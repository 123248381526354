import Header from './components/header/header';
import Footer from './components/footer/footer';
import Whatsup from './components/whatsapp/whatsapp';

import {Car,Pulimalai, Anakulam, kolukumalai, Topstation, kundalalake, Boating, mattupatti, Eleride, Photopoint, Garden, Elearrive, Tea, Echopoint, bgraden,  image1, room, sightseeing, boathouse, honeymoon, jeep, nightsafari, fullpackage, pickdrop } from './images/image';
import video1 from './videos/video1.mp4'

import './App.css';
const Home = () => {
  return(
  <>
    <Header />
    <div className='heroBanner flex center middle'>
      <div className='container px-2 md-px-4 relative zindex-1'>
        <div className='content flex col gap-10'>
          <div className='bannerTitle text-center flex col gap-4'>
            <h1>Where Would You Like To Go?</h1>
            <p>Checkout Beautiful Places Arround the Munnar.</p>
          </div>
          <div className='content-box w-full'>
            <div className='flex col gap-1 md-flex md-row md-middle md-gap-2 text-center px-4 py-4 md-py-2'>
            <p>Book your <span>Thathwamasi Tour's</span>  Service today and embark on an extraordinary journey in the lap of nature!</p>
            <div className='flex-1 gap-1'></div>
            <a href='tel:+919496452926'><button>Book Now</button></a>
            </div>
          </div>
          <h3 className='text-center'>Journey to Munnar: Where Nature Paints a Masterpiece!</h3>
        </div>
      </div>
      <div className='video'><iframe src="https://www.youtube.com/embed/n1woB4jm6SA?vq=hd1080&autoplay=1&mute=1&playsinline=1&loop=1&playlist=n1woB4jm6SA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe></div>
    </div>
    <div className='section-one relative'>
      <div className='container px-2 md-px-4'>
        <div className='box-container flex wrap center gap-2'>
          <div className='box-cover flex col gap-4'>
            <h3>Professional Drivers</h3>
            <p>Our team of experienced and well-trained drivers are not just experts on the roads, but also passionate about showcasing the best of Munnar.</p>
          </div>
          <div className='box-cover flex col gap-4'>
            <h3>Customized Itineraries</h3>
            <p>Munnar is a treasure trove of natural wonders. Our team can help you create personalized itineraries based on your interests and preferences.</p>
          </div>
          <div className='box-cover flex col gap-4'>
            <h3>Punctuality &amp; Reliability</h3>
            <p>We value your time and understand the importance of punctuality when it comes to exploring a destination.</p>
          </div>
          <div className='box-cover flex col gap-4'>
            <h3>Competitive Pricing</h3>
            <p>We believe that quality service doesn't have premium price. Our taxi services are competitively priced, offering excellent value for your money.</p>
          </div>
          <div className='box-cover flex col gap-4'>
            <h3>Safety Measures</h3>
            <p>We adhere to strict safety protocols and follow all necessary guidelines to ensure a safe and secure journey for you and your loved ones.</p>
          </div>
        </div>
      </div>
    </div>
    <div className='section-two relative'>
      <div className='container px-2 md-px-4 pb-4'>
        <div className='flex col overflow-hidden py-4 middle center gap-6 pt-10'>
        <div className='title flex col gap-4'>
          <div className='flex center'>
            <p>We Offering</p>
          </div>
          <h2>Embark on the journey of a lifetime, book your custom tour today!</h2>
        </div>
        <div className='place-container w-full flex wrap center gap-4'>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Pickup & drop
            </span>
            <img src={pickdrop} alt='pic' />
          </div>
          <div className='place-box flex relative '>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Room Booking
            </span>
            <img src={room} alt='pic' />
          </div>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Sightseeing
            </span>
            <img src={sightseeing} alt='pic' />
          </div>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Alleppey boat house booking
            </span>
            <img src={boathouse} alt='pic' />
          </div>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Night Safari
            </span>
            <img src={nightsafari} alt='pic' />
          </div>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Honeymoon Package
            </span>
            <img src={honeymoon} alt='pic' />
          </div>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Jeep Safari
            </span>
            <img src={jeep} alt='pic' />
          </div>
          <div className='place-box flex relative'>
            <span className='tag flex absolute zindex-1 middle center'>15% offer</span>
            <span className='shadow absolute zindex-3 bottom-0'>
              Package tour
            </span>
            <img src={fullpackage} alt='pic' />
          </div>
        </div>
        </div>
      </div>
    </div>
    <div className='section-three relative'>
      <div className='container px-2 md-px-4 py-8'>
        <div className='flex col gap-4 lg-flex lg-row'>
          <div className='flex-1 title flex col gap-4'>
            <span className=' flex middle center '>
              Welcome to Thathwamasi Tour's
            </span>
            <div className='about-head flex col gap-6 md-text-center'>
              <h2>Experience the Magic of Munnar with Us!</h2>
              <p>Thathwamasi Tour's Service is your reliable partner in exploring the breathtaking beauty of Munnar. Whether you're a solo traveler, a couple on a romantic getaway, or a family seeking an adventure, we have the perfect transportation solutions for you. Sit back, relax, and let us take care of all your travel needs while you create unforgettable memories amidst the stunning landscapes of Munnar.</p>
            </div>
          </div>
          <div className='flex-1'>
            <div className='about-image flex center '>
              <img src={image1} alt='about' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='section-four'>
      <img src={Car} className=' car one ' alt='car' />
      <img src={Car} className=' car two ' alt='car' />
      <img src={Car} className=' car three ' alt='car' />
      <h2 className='title flex center middle'>Discover the Unforgettable Charm of Munnar</h2>
      <p className='title flex center middle'>Nature's Paradise in the Western Ghats!</p>
      <div className='road' />
    </div>
    <div className='section-five'>
      <div className='container px-2 md-px-4 py-4 md-py-10'>
        <div className='videocontainer flex col lg-flex lg-row'>
          <div className=' flex-1 wrap center  '>
            <video src={video1} className='flex middle center' controls={true}  />
          </div>
          <div className='flex-1 p-4'>
            <div className='flex col gap-6'>
              <div className='flex middle col gap-2'>
                <span className='flex middle center px-2 py-1'>
                  Availability
                </span>
                <h2>Enjoy real adventure</h2>
              </div>
              <div className='flex middle col gap-2'>
                <div>
                    <div className='videobox flex middle px-4 py-2'>
                      How Much Price About Tour & Travels
                    </div>
                    <div className='videobox flex middle px-4 py-2'>
                      We're giving all the best services to you
                    </div>
                    <div className='videobox flex middle px-4 py-2'>
                      Best Experience Travel Agency
                    </div>
                </div>
                <div className='flex middle center  '>
                  <a href='tel:+919496452926 '  className='booknow flex py-2 px-4'>Book Now </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='section-six pt-4'>
      <div className='flex gap-2 nowrap'>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Flower Garden</span>
          <img src={Garden} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Photo Point</span>
          <img src={Photopoint} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Elephant Ride</span>
          <img src={Eleride} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Botanical Garden</span>
          <img src={bgraden} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Tea Factory Visit</span>
          <img src={Tea} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Madupatty Dam</span>
          <img src={mattupatti} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Boating</span>
          <img src={ Boating} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Elephant Arrival Spot</span>
          <img src={Elearrive} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Echo Point</span>
          <img src={Echopoint} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Kundala Lake</span>
          <img src={kundalalake} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Topstation</span>
          <img src={Topstation} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Kolukkumala</span>
          <img src={kolukumalai} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Anakulam</span>
          <img src={Anakulam} alt='visit' />
        </div>
        <div className='visitbox flex relative'>
          <span className='background absolute zindex-2'>Meesapulimala</span>
          <img src={Pulimalai} alt='visit' />
        </div>
      </div>
    </div>
    <Footer />
    <Whatsup />
  </>
      )
}

export default Home;
