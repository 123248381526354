import Header from './components/header/header';
import Footer from './components/footer/footer';
import Whatsup from './components/whatsapp/whatsapp';
import './Touristspot.css';
import { Pulimalai, Anakulam, kolukumalai, Topstation, kundalalake, Boating, mattupatti, Eleride, Photopoint, Garden, Elearrive, Tea, Echopoint, bgraden,  } from './images/image';

const Home = () => { 
  return(
  <>
    <Header />
      <div className='gallery'>
        <div className='container'>
          <div className='gallerycontainer flex px-2 py-10'>
            <div className='flex col gap-4'>
              <h1>Thathwamasi Tour's</h1> 
                <div className='flex '>
                  <h2>Top Spot's 1</h2>
                </div> 
                <div className='gallerytwo flex row wrap gap-2 center md-flex md-left  '>
                  <div className='spotbox flex col gap-1'>
                    <img src={Garden} alt='places' />
                    <p className='flex  center'>Flower Garden</p>
                  </div>
                  <div className='spotbox flex col gap-1'>
                    <img src={Photopoint} alt='places' />
                    <p className='flex center'>Photo Point</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={Eleride} alt='places' />
                    <p className='flex center '>Elephant Ride</p>
                  </div>
                  <div className='spotbox flex col gap-1'>
                    <img src={bgraden} alt='places' />
                    <p className='flex center '>Botanical Garden</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={Tea} alt='places' />
                    <p className='flex center '>Tea Factory Visit</p>
                  </div>
                </div>
                <div className='flex '>
                  <h2>Top Spot's 2</h2>
                </div> 
                <div className='gallerytwo flex row wrap gap-2 center md-flex md-left  '>
                  <div className='spotbox flex col gap-1 '>
                    <img src={mattupatti} alt='places' />
                    <p className='flex center '>Madupatty Dam</p>
                  </div>
                  <div className='spotbox flex col gap-1'>
                    <img src={Boating} alt='places' />
                    <p className='flex center '>Boating</p>
                  </div>
                  <div className='spotbox flex col gap-1'>
                    <img src={Elearrive} alt='places' />
                    <p className='flex center '>Elephant Arrival Spot</p>
                  </div>
                  <div className='spotbox flex col gap-1'>
                    <img src={Echopoint} alt='places' />
                    <p className='flex center '>Echo Point</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={kundalalake} alt='places' />
                    <p className='flex center '>Kundala Lake</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={Topstation} alt='places' />
                    <p className='flex center '>Topstation</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={Pulimalai} alt='places' />
                    <p className='flex center '>Meesapulimala</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={Anakulam} alt='places' />
                    <p className='flex center '>Anakulam</p>
                  </div>
                  <div className='spotbox flex col gap-1 '>
                    <img src={kolukumalai} alt='places' />
                    <p className='flex center '>Kolukkumala</p>
                  </div>
                  </div>
                </div>
          </div>
        </div>  
      </div>   
    <Footer />
    <Whatsup />    

  </>
  )
}
export default Home;
