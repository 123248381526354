import { Whatsapp } from "../../Icons";

import './whatsapp.css'

const Whatsup = () => {
    return(
        <div className='whatsappfooter flex center middle'>
         <a href="//api.whatsapp.com/send?phone=919496452926&text=Hi thathwamasi,">  <Whatsapp /></a>
        </div>
    )
}
export default Whatsup;