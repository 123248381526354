import { useNavigate } from "react-router-dom";
import { Logo} from '../../images/image';
import { HomeFooterIcon,SpotFooterIcon } from "../../Icons";
import './header.css';

const Header = () => {
  const navigate = useNavigate();
  
  const goNavigate = (e, url) => {
    e.preventDefault()
    window.scrollTo(0,0);
    navigate(url)
  };
  return(
      <div className='header'>
        <div className='menu hide md-flex px-2 py-2 lg-px-4 w-full'>
          <img src={Logo} className='Logo' alt='Logo' />
          <div className='md-flex-1 '></div>
          <div className='flex gap-6 middle'>
            <div className='nav hide md-flex  md-gap-6'>
              <button onClick={(e)=>goNavigate(e, "/")}>Home</button>
              <button onClick={(e)=>goNavigate(e, "/touristspot")}>Tourist spot</button>
            </div>
             <a href='tel:+919496452926'>Book Now</a>
          </div>
        </div>
        <div className='md-menu flex md-hide' style={{justifyContent:"space-between"}}>
          <div className="icon"><a href="/" onClick={(e)=>goNavigate(e, "/")}>
            <HomeFooterIcon />
            <span>Home</span>
          </a></div>
          <div className="icon main"><span><img src="/logo192.png" alt="logo" /></span></div>
          <div className="icon"><a href="/" onClick={(e)=>goNavigate(e, "/touristspot")}>
            <SpotFooterIcon />
            <span>Spot</span>
          </a></div>
        </div>
      </div>
  );
}
export default Header;