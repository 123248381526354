import { Call, Facebook, Insta, Linkedin,Massage,Location} from '../../Icons';
import { Logo } from '../../images/image';

import './footer.css';

const Footer = () => {
    return(
    <div className='footer p-2 md-p-4 	 '>
        <div className='footerlogo flex col gap-8 lg-flex lg-row lg-gap-2'>
            <div className='flex-1 flex col gap-5'>
              <div className='flex col gap-3'>
                <div className='img relative flex'>
                    <img src={Logo} alt='logo' />
                </div>
                <h6 className='pl-4'>To take trivial example which us ever undertakes laborious physica exercise except obsome.</h6>
              </div>
              <div className='footerapp pl-4'>
                <ol className='flex row gap-2'>
                    
                    <li>
                        <a href='https://www.facebook.com/profile.php?id=100093999931888&mibextid=ZbWKwL'>
                            <Facebook />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/teamthathwamasi/'>
                            <Insta />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.linkedin.com/in/team-thathwamasi-70155427b/'>
                            <Linkedin />
                        </a>
                    </li>
                </ol>
              </div>
            </div>
            <div className='flex-1 flex left lg-flex lg-center footercontact pl-0 sm-pl-4'>
                <div className='flex col gap-5'>
                    <h3>Contact</h3>
                    <div className='contact flex row gap-2'>
                        <button><Call /></button>
                        <div className='flex col gap-1'>
                            <h4>Drop a Line</h4>
                            <h3>8281583926,9496452926</h3>
                        </div>
                    </div>
                    <div className='contact flex row gap-2'>
                        <button><Massage /></button>
                        <div className='flex col gap-1'>
                            <h4>Email Address</h4>
                            <h3>thathwamasitours3r@gmail.com</h3>
                        </div>
                    </div>
                    <div className='contact flex row gap-2'>
                        <button><Location /></button>
                        <div className='flex col gap-1'>
                            <h4>Location</h4>
                            <h3>Munnar</h3>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;